import React, { Component } from 'react';

import PageTitle from '../layouts/PageTitle';
import Pagination from '../layouts/Pagination';
import Card from './Card';

export default class Main extends Component {
  constructor(props) {
    super(props);

    const { content } = props;

    this.state = {
      content: [...content],
      pageList: [...content],
      pageOfItems: []
    };
  }

  onChangePage = pageOfItems => {
    // update state with new page of items
    this.setState({ pageOfItems: pageOfItems });
  };

  render() {
    const { pageList, pageOfItems } = this.state;
    return (
      <div>
        <PageTitle
          title='Bilirkişi Değiliz | Anasayfa'
          metaContent='Bilirkişi Değiliz Anasayfası'
        />

        <section className='section'>
          <div className='container'>
            <div className='columns is-multiline is-centered'>
              {pageOfItems.map(icerik => (
                <Card key={icerik.id} icerik={icerik} />
              ))}
            </div>

            <div>
              <Pagination
                items={pageList}
                onChangePage={this.onChangePage}
                initialPage={1}
                perPage={9}
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
}
