import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div>
      <footer className='footer is-paddingless has-background-link has-text-white'>
        <div className='content has-text-centered'>
          <div>
            <Link className='item has-text-white' to='/'>
              <span className='icon is-medium'>
                <i className='fas fa-lg fa-home'></i>
              </span>
            </Link>{' '}
            <Link className='item has-text-white' to='/hakkimizda'>
              <span className='icon is-medium'>
                <i className='fas fa-lg fa-info-circle'></i>
              </span>
            </Link>{' '}
            <a
              className='item has-text-white'
              href='https://www.youtube.com/channel/UCcLtAHF4QOtolYRIX5zgjig'
              target='_blank'
              rel='noopener noreferrer'
            >
              <span className='icon is-medium'>
                <i className='fab fa-lg fa-youtube'></i>
              </span>
            </a>{' '}
            <a
              className='item has-text-white'
              href='https://www.instagram.com/bilirkisidegiliz'
              target='_blank'
              rel='noopener noreferrer'
            >
              <span className='icon is-medium'>
                <i className='fab fa-lg fa-instagram'></i>
              </span>
            </a>{' '}
            <a
              className='item has-text-white'
              href='https://twitter.com/bilirkisidegil'
              target='_blank'
              rel='noopener noreferrer'
            >
              <span className='icon is-medium'>
                <i className='fab fa-lg fa-twitter'></i>
              </span>
            </a>
          </div>
          <div className='has-text-weight-medium'>Bilirkişi Değiliz © 2020</div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
