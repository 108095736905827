import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: false
    };
  }

  render() {
    const { menu } = this.state;

    return (
      <div>
        <nav
          className='navbar is-link'
          role='navigation'
          aria-label='main navigation'
        >
          <div className='navbar-brand'>
            <Link className='navbar-item' to='/'>
              <img className='logo' src='/static/img/logo.png' alt='logo' />
            </Link>

            <div
              role='button'
              className={
                menu ? 'navbar-burger burger is-active' : 'navbar-burger burger'
              }
              aria-label='menu'
              aria-expanded='false'
              onClick={() => this.setState({ menu: !menu })}
            >
              <span aria-hidden='true'></span>
              <span aria-hidden='true'></span>
              <span aria-hidden='true'></span>
            </div>
          </div>

          <div className={menu ? 'navbar-menu is-active' : 'navbar-menu'}>
            <div className='navbar-start'>
              <Link to='/' className='navbar-item is-hidden-desktop'>
                <i className='fas fa-home'></i> Anasayfa
              </Link>

              <Link to='/' className='navbar-item is-hidden-touch'>
                <strong>
                  <i className='fas fa-home'></i> Anasayfa
                </strong>
              </Link>
            </div>

            <div className='navbar-end'>
              <Link to='/hakkimizda' className='navbar-item is-hidden-desktop'>
                <i className='fas fa-info-circle'></i> Hakkımızda
              </Link>

              <Link to='/hakkimizda' className='navbar-item is-hidden-touch'>
                <strong>
                  <i className='fas fa-info-circle'></i> Hakkımızda
                </strong>
              </Link>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
