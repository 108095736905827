import React from 'react';
import Share from '../layouts/Share';

export default function Card(props) {
  const { icerik } = props;

  return (
    <div className='column is-one-third'>
      <div className='card'>
        <div className='card-image'>
          <figure className='image is-4by3'>
            <iframe
              className='has-ratio'
              width='640'
              height='360'
              title={icerik.title}
              src={icerik.video}
              frameBorder='0'
              allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            ></iframe>
          </figure>
        </div>
        <div className='card-content'>
          <p className='title is-5'>{icerik.title}</p>

          <div className='content'>
            {icerik.description}
            <hr />
            <span className='is-italic is-size-7'>
              <time>{icerik.date}</time>
            </span>{' '}
            <Share title={icerik.title} url={icerik.video} />
          </div>
        </div>
      </div>
    </div>
  );
}
