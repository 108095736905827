import React from 'react';

import PageTitle from '../layouts/PageTitle';

export default function About() {
  return (
    <section className='section'>
      <PageTitle
        title='Bilirkişi Değiliz | Hakkımızda'
        metaContent='Bilirkişi Değiliz Hakkımızda Sayfası'
      />

      <div className='container'>
        <div className='box content'>
          <h1 className='is-size-2'>Hakkımızda</h1>

          <p>
            <span className='is-size-5'>
              Bilirkişi Değiliz, Ömer ve Furkan tarafından sunulan bir internet
              programıdır. Şu an için programda kitap incelemeleri
              yapmaktadırlar.
            </span>
          </p>

          <br />

          <div className='columns is-multiline is-centered'>
            <div className='column is-half'>
              <div className='card'>
                <div className='card-content'>
                  <div className='media'>
                    <div className='media-left'>
                      <figure className='image about-profile-photo'>
                        <img
                          className='is-rounded'
                          src='/static/img/omer.jpeg'
                          alt='Ömer Profil Fotoğrafı'
                        />
                      </figure>
                    </div>
                    <div className='media-content about-profile-content'>
                      <p className='title is-2'>Ömer</p>
                      <p className='subtitle is-6'>
                        <a
                          className='item'
                          href='https://www.instagram.com/omartpcu'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <span className='icon is-medium'>
                            <i className='fab fa-lg fa-instagram'></i>
                          </span>
                        </a>{' '}
                        <a
                          className='item'
                          href='#'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <span className='icon is-medium'>
                            <i className='fab fa-lg fa-twitter'></i>
                          </span>
                        </a>
                      </p>
                    </div>
                  </div>

                  <div className='content'>-</div>
                </div>
              </div>
            </div>
            <div className='column is-half'>
              <div className='card'>
                <div className='card-content'>
                  <div className='media'>
                    <div className='media-left'>
                      <figure className='image about-profile-photo'>
                        <img
                          className='is-rounded'
                          src='/static/img/furkan.jpeg'
                          alt='Furkan Profil Fotoğrafı'
                        />
                      </figure>
                    </div>
                    <div className='media-content about-profile-content'>
                      <p className='title is-2'>Furkan</p>
                      <p className='subtitle is-6'>
                        <a
                          className='item'
                          href='https://www.instagram.com/st.furkan'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <span className='icon is-medium'>
                            <i className='fab fa-lg fa-instagram'></i>
                          </span>
                        </a>{' '}
                        <a
                          className='item'
                          href='#'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <span className='icon is-medium'>
                            <i className='fab fa-lg fa-twitter'></i>
                          </span>
                        </a>{' '}
                      </p>
                    </div>
                  </div>

                  <div className='content'>-</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
