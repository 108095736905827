import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import Header from './components/layouts/Header';
import Footer from './components/layouts/Footer';
import NotFound from './components/layouts/NotFound';

import Main from './components/main/Main';
import About from './components/about/About';

import 'bulma/css/bulma.css';
import './main.css';

let content = require('./content/content.json');

let sortedContent = [...content].sort((a, b) => (b.id > a.id ? 1 : -1));

function App() {
  return (
    <HelmetProvider>
      <Router>
        <div className='body-content'>
          <Header />
          <div className='main-content'>
            <Switch>
              <Route
                exact
                path='/'
                component={props => <Main {...props} content={sortedContent} />}
              />

              <Route exact path='/hakkimizda' component={() => <About />} />

              <Route component={() => <NotFound />} />
            </Switch>
          </div>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
